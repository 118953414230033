import React from 'react';
// import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';

export default [
  {
    key: 'transaction',
    path: '/transaction',
    name: 'Transaction Monitoring',
    icon: <ReceiptOutlinedIcon />,
    permission: ['ADMIN'],
    children: [
      // {
      //   key: 'dmi.transaction-monitoring.deposit',
      //   path: '/transaction/customer-transaction',
      //   name: 'Customer Transaction',
      //   role: [],
      // },
      {
        key: 'dmi.transaction-monitoring.deposit',
        path: '/transaction/cico',
        name: 'Cash In & Cash Out',
        role: [],
      },
    ],
  },
  // {
  //   key: 'merchant',
  //   path: '/merchant',
  //   name: 'Merchant Management',
  //   icon: <StorefrontIcon />,
  //   permission: ['ADMIN'],
  //   children: [
  //     {
  //       key: 'merchant.manage.monitoring',
  //       path: '/merchant/manage',
  //       name: 'Manage Merchant',
  //       role: [],
  //     },
  //     {
  //       key: 'merchant.deposit.list',
  //       path: '/merchant/deposit',
  //       name: 'Deposit',
  //       role: [],
  //     },
  //     {
  //       key: 'merchant.deposit.update-balance-approve',
  //       path: '/merchant/deposit/approval',
  //       name: 'Deposit Approval',
  //       role: [],
  //     },
  //     {
  //       key: 'merchant.manage.approve-reset-credential',
  //       path: '/merchant/credential/approval',
  //       name: 'Reset Credential Approval',
  //       role: [],
  //     },
  //   ],
  // },
  // {
  //   key: 'settings',
  //   path: '/role',
  //   name: 'Settings',
  //   icon: <SettingsApplicationsOutlinedIcon />,
  //   permission: ['ADMIN'],
  //   children: [
  //     {
  //       key: 'settings.manage-role.list',
  //       path: '/role',
  //       name: 'Manage Role',
  //       role: [],
  //     },
  //     {
  //       key: 'settings.manage-users.list',
  //       path: '/user',
  //       name: 'Manage Users',
  //       role: [],
  //     },
  //   ],
  // },
];
