import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';

import SignIn from 'src/components/pages/login';
import Page404 from 'src/components/pages/page-404';
import DashboardLayout from 'src/components/layouts/dashboard';
import DefaultLayout from 'src/components/layouts/default';
import { IState } from 'src/types/state';
import ScrollToTop from 'src/components/atoms/scroll-to-top';
import { getToken } from 'src/utils/local-storage';
import { getRole, userLogin } from 'src/redux/auth/actions';
import history from './history';
import publicRoutes from './public';

interface PropTypes {
  isLoggedin: boolean;
  uid?: string | number;
}

const Routes: React.FunctionComponent<PropTypes> = ({
  isLoggedin = false,
  uid = null,
}: PropTypes) => {
  const dispatch = useDispatch();
  const token = getToken();
  if (token) {
    dispatch(userLogin({ token }));
  }
  // FETCH ROLE
  useEffect(() => {
    if (uid) {
      dispatch(getRole());
    }
  }, [uid]);
  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          {
            publicRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                component={route.component}
                exact={false}
              />
            ))
          }
          {!isLoggedin && (
            <DefaultLayout>
              <SignIn />
            </DefaultLayout>
          )}
          {isLoggedin && (
            <Route
              key="guarded-routes"
              component={DashboardLayout}
              exact={false}
            />
          )}
          <Route
            render={() => (
              <DefaultLayout>
                <Page404 />
              </DefaultLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

const mapStateToProps = (state: IState) => ({
  isLoggedin: state.auth.isLoggedin,
  uid: state.auth.userData.id,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  React.memo,
)(Routes);
