import React from 'react';
import SiginForm from 'src/components/organisms/form-sign-in';
import ForgotpassForm from 'src/components/organisms/form-forgot-password';
import './index.scss';

const PageLogin = () => {
  const [forgotPassView, setForgotPassView] = React.useState(false);
  return (
    <div className="login">
      <div className="login_wrapper">
        <div className="login_welcome text-center">
          <h2>{forgotPassView ? 'Forgot password ?' : 'Welcome back'}</h2>
          <p className="lead">
            {forgotPassView ? 'To reset your password, enter your dimiiland registered email.'
              : 'Sign in to your account to continue'}
          </p>
        </div>
        <div className="login_card">
          {forgotPassView ? (<ForgotpassForm backToLogin={() => setForgotPassView(false)} />)
            : (<SiginForm forgotPass={() => setForgotPassView(true)} />)}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PageLogin);
