import async from '../components/atoms/async';

const ResetPassword = async(() => import('src/components/pages/reset-password'));
const UserSuccess = async(() => import('src/components/pages/user-success'));
const UserInvite = async(() => import('src/components/pages/user-invite'));

const publicRoute = [
  {
    auth: false,
    path: '/reset-password',
    key: [],
    component: ResetPassword,
    title: 'Reset Password',
  },
  {
    auth: false,
    path: '/user/success',
    key: [],
    component: UserSuccess,
    title: 'Create Password Success',
  },
  {
    auth: false,
    path: '/user/invite',
    key: [],
    component: UserInvite,
    title: 'User Invited',
  },
];

export default publicRoute;
