import React from 'react';
import { IState } from 'src/types/state';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { toggleModal } from 'src/redux/app/actions';
import { IModal } from 'src/types/app/state';
import ModalStateless from '@capitalx/web-ui-shared-components/lib/components/atoms/modal';

interface PropTypes {
  modal: IModal;
}

const Modal: React.FunctionComponent<PropTypes> = ({ modal = {} }: PropTypes) => {
  // CONNECTED COMPONENT
  const dispatch = useDispatch();
  const {
    open,
  } = modal;
  // OTHER
  const onClose = () => {
    dispatch(toggleModal({
      open: false,
      component: null,
      header: '',
      hasHeader: true,
    }));
  };
  // CLEANUP / RESET AFTER MODAL CLOSE
  React.useEffect(() => {
    if (!open) {
      onClose();
    }
  }, [open]);

  return (
    <ModalStateless
      onClose={onClose}
      open={open}
      {...modal}
    />
  );
};

const mapStateToProps = (state: IState) => ({
  modal: state.app.modal,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  React.memo,
)(Modal);
