import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'redux';
// Material UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
// other necessities
// import logo from 'src/assets/img/logo/logo.png';
import menus from 'src/components/layouts/menu';
import findRolePermission from 'src/utils/role';
import { randomString } from 'src/utils/string';
import { IRouteHistory, IRouteLocation, IRouteMatch } from 'src/types/router';
import { IState } from 'src/types/state';
import makeCustomStyles from './style';
import './_index.scss';

interface listPropsType {
  [key: string]: boolean;
}

interface propsType {
  match?: IRouteMatch;
  location?: IRouteLocation;
  history?: IRouteHistory;
  role?: string[];
  loading?: boolean;
  onNavItemPress: () => void,
}

const collapseBuilder = (menuList: any[]): listPropsType => {
  let formattedCollapse = {};
  for (let index = 0; index < menuList.length; index += 1) {
    const menu = menuList[index];
    formattedCollapse = Object.assign(formattedCollapse, {
      [menu.key]: false,
    });
  }
  return formattedCollapse;
};

const onOpenSubMenu = (key, isOpen, openCb) => {
  openCb({
    ...isOpen,
    [key]: !isOpen[key],
  });
};

const Sidebar: React.FunctionComponent<propsType> = (props: propsType) => {
  const {
    role,
    location: { pathname },
    loading,
    history,
    onNavItemPress,
  } = props;

  // Open list state
  const [isOpen, setOpen] = useState<listPropsType>(collapseBuilder(menus));
  const styles = makeCustomStyles();

  const handleOnPressedNavItem = (navigationLink) => {
    onNavItemPress();
    history.push(navigationLink);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      classes={{
        root: styles.listRoot,
      }}
    >
      <ListItem>
        <div className="list_logo">
          {/* <img src={logo} alt="Remid" /> */}
          <h1>DMI</h1>
        </div>
      </ListItem>
      <Divider />
      {
        loading && (
          <>
            {
              [...Array(4)].map(() => (
                <span key={randomString()} className="sidebar_skeleton-wrapper flex ml-2">
                  <Skeleton classes={{ root: styles.skeletonRoot }} variant="circle" width={30} height={30} />
                  <Skeleton classes={{ root: styles.skeletonRoot }} variant="rect" height={30} width="75%" />
                </span>
              ))
            }
          </>
        )
      }
      <div className="sidebar_item-wrapper">
        {
          !loading && role && role.length !== 0 && menus.map((menu) => (
            <React.Fragment key={menu.path}>
              {
                findRolePermission(menu.key) ? (
                  (() => {
                    if (menu.children) {
                      return (
                        <ListItem
                          button
                          onClick={() => onOpenSubMenu(menu.key, isOpen, setOpen)}
                        >
                          <ListItemIcon className={styles.listIconRoot}>
                            {menu.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={menu.name}
                            classes={{
                              primary: styles.listTextPrimary,
                            }}
                          />
                          { isOpen[menu.key] ? <ExpandLess /> : <ExpandMore /> }
                        </ListItem>
                      );
                    }
                    return (
                      <ListItem>
                        <ListItemIcon className={styles.listIconRoot}>
                          {menu.icon}
                        </ListItemIcon>
                        <NavLink
                          to={menu.path}
                          style={{
                            textDecoration: 'none',
                          }}
                        >
                          <ListItemText
                            primary={menu.name}
                            classes={{
                              primary: styles.listTextPrimary,
                            }}
                          />
                        </NavLink>
                      </ListItem>
                    );
                  })()
                ) : null
              }
              {
                menu.children.map((child) => (
                  <Collapse
                    key={child.path}
                    in={isOpen[menu.key]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {
                        findRolePermission(child.key) && (
                          <ListItem
                            button
                            className={styles.nested}
                            onClick={() => handleOnPressedNavItem(child.path)}
                          >
                            <ListItemText
                              primary={child.name}
                              classes={{
                                primary: pathname.includes(child.path)
                                  ? styles.subListTextPrimaryActive
                                  : styles.subListTextPrimary,
                              }}
                            />
                          </ListItem>
                        )
                      }
                    </List>
                  </Collapse>
                ))
              }
            </React.Fragment>
          ))
        }
      </div>
    </List>
  );
};

const mapStateToProps = (state: IState, _: any = {}) => ({
  sidebar: state.app,
  role: state.auth.userData.role,
  loading: state.auth.loading.role,
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect, React.memo)(withRouter(Sidebar));
