import { fetcher } from 'src/services';
import { IHttpResponse } from 'src/types/service/http';
import { ILoginResponse, IPermissionResponse } from 'src/types/auth/http';

const example = {
  length: 1,
  data: [
    {
      id: 'DEV705225',
      email: 'superadmin@superadmin.com',
      name: 'Name A',
      status: 1,
      role: 'superadmin',
      password: 'superadmin',
    },
    {
      id: 'DEV696649',
      email: 'admin@superadmin.com',
      name: 'Name B',
      status: 1,
      role: 'superadmin',
      password: 'admin12345',
    },
    {
      id: 'DEV626247',
      email: 'ahmad@superadmin.com',
      name: 'Name C',
      status: 0,
      role: 'superadmin',
      password: 'admin12345',
    },
  ],
};

const loginUser = (email, password) => {
  /* eslint-disable */
  // [NOTE]: To the creator of the code, please refactor based on npm run lint warning
  new Promise((resolve) => {
    if (email) {
      const emailData = example.data.find((user) => user.email === email);
      if (emailData) {
        const passMatch = example.data.find((user) => user.password === password);
        if (passMatch) {
          resolve(passMatch);
        } else {
          return false;
        }
      } else {
        return 'Email not found';
      }
    }
  });
  /* eslint-enable */
};

const doCheckToken = async (token) => {
  try {
    const response: IHttpResponse<any> = await fetcher.get(`/user/invite?token=${token}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const doCreatePass = async (password, token) => {
  try {
    const response: IHttpResponse<any> = await fetcher.put(`/user/new-password?token=${token}`, ({
      password,
    }));
    return response;
  } catch (error) {
    throw error;
  }
};

const doCheckResetPassToken = async (token) => {
  try {
    const response: IHttpResponse<any> = await fetcher.get(`/user/password-reset?token=${token}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const doResetPass = async (token, password) => {
  try {
    const response: IHttpResponse<any> = await fetcher.put(`/user/password-reset?token=${token}`, ({
      password,
    }));
    return response;
  } catch (error) {
    throw error;
  }
};

const doLogin = async (email, password) => {
  try {
    const response: IHttpResponse<ILoginResponse> = await fetcher.post('/user/login', ({
      user_name: email,
      password,
    }));
    return response;
  } catch (error) {
    throw error;
  }
};

const getAllRoles = async () => {
  try {
    const response: IHttpResponse<any> = await fetcher.get('/roles');
    return response;
  } catch (error) {
    throw error;
  }
};

const getPermission = async () => {
  try {
    const response: IPermissionResponse = await fetcher.get('/user/permissions');
    return response;
  } catch (error) {
    throw error;
  }
};

const doLogout = async (email) => {
  try {
    const response: IHttpResponse<any> = await fetcher.post('/user/logout', ({
      user_name: email,
    }));
    return response;
  } catch (error) {
    throw error;
  }
};

const doForgotPass = async (email) => {
  try {
    const response: IHttpResponse<any> = await fetcher.post('/user/password-reset', ({
      email,
    }));
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  loginUser,
  doCheckToken,
  doCreatePass,
  doCheckResetPassToken,
  doResetPass,
  doLogin,
  doLogout,
  doForgotPass,
  getAllRoles,
  getPermission,
};
