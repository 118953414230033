import { connect } from 'react-redux';
import { compose } from 'redux';
import React from 'react';
import { ISnackbar } from 'src/types/app/state';
import SnackbarStateless from '@capitalx/web-ui-shared-components/lib/components/atoms/snackbar';

interface PropTypes {
  snackbar: ISnackbar
}

const Snackbar: React.FunctionComponent<PropTypes> = ({ snackbar }: PropTypes) => {
  return <SnackbarStateless {...snackbar} />;
};

const mapStateToProps = (state) => ({
  snackbar: state.app.snackbar,
});

const withConnect = connect(mapStateToProps, null);

const withCompose = compose(withConnect, React.memo);

export default withCompose(Snackbar);
