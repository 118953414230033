import async from '../components/atoms/async';

const CashInCashOut = async(() => import('src/components/pages/transaction-cico'));
const CashInCashOutDetail = async(() => import('src/components/pages/transaction-cico-detail'));

const transaction = [
  {
    auth: true,
    path: '/transaction/cico',
    key: 'dmi.transaction-monitoring.deposit',
    layout: 'sidebar',
    component: CashInCashOut,
  },
  {
    auth: true,
    path: '/transaction/cico/detail/:id',
    key: 'dmi.transaction-monitoring.deposit',
    layout: 'sidebar',
    component: CashInCashOutDetail,
  },
];

export default transaction;
