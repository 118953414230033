import { v4 as uuidv4 } from 'uuid';
import Querystring from 'query-string';

const parseToQueryString = (obj: any) => Object
  .keys(obj)
  .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
  .join('&');

export const randomString = () => uuidv4();

export const parseFromQueryString = (querystring: string) => Querystring.parse(querystring);

export const capitalizeFirstLetter = (queryString?: string) => (
  queryString.charAt(0).toUpperCase() + queryString.slice(1)
);

export const formatColumnNameToString = (columnName ?: string) => {
  let newColumnName = '';

  if (columnName) {
    const arrayColumnName = columnName.split('_');

    for (let i = 0; i < arrayColumnName.length; i += 1) {
      newColumnName += capitalizeFirstLetter(arrayColumnName[i]);

      if (i !== arrayColumnName.length - 1) {
        newColumnName += ' ';
      }
    }
  }

  return newColumnName || columnName;
};

export default parseToQueryString;
