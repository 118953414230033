import roleRoutes from './role';
import manageUsers from './user';
import auditTrail from './audit-trail';
import home from './home';
import transaction from './transaction';

export default [
  ...home,
  ...roleRoutes,
  ...manageUsers,
  ...auditTrail,
  ...transaction,
];
